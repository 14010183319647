import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EHRButton } from 'src/components/ui/v1';
import { useMerchantOnboarding } from 'src/hooks/mutations/useMerchantOnboarding';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { RootState } from 'src/rematch';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { useMerchantAccount } from 'src/hooks/queries/medspaAdmins/useMerchantAccount';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING, STEP_PENDING } from 'src/components/StepsTracker';
import { Box, CircularProgress } from '@material-ui/core';

interface Props {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
}

const PaymentManagementSetup = ({ stepId, onStepUpdate }: Props) => {
  const { paymentProcessor, userGroupId } = useSelector(({ auth }: RootState) => auth);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { data: merchantAccount, isLoading, isError } = useMerchantAccount(userGroupId ?? 0);
  const { mutate: onboardMerchant } = useMerchantOnboarding();

  const stripeOnboarding = async () => {
    setIsRedirecting(true);
    onboardMerchant({ country: 'US' });
  };
  const onboardingStatus = merchantAccount?.['payments/merchantAccount'].onboardingStatus;

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError]);

  useEffect(() => {
    if (!merchantAccount) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
    if (onboardingStatus === 'in_progress') {
      onStepUpdate(stepId, STEP_PENDING);
    }
    if (onboardingStatus === 'completed' || merchantAccount?.['payments/merchantAccount'].canProcessPayments) {
      onStepUpdate(stepId, STEP_COMPLETED);
    }
  }, [merchantAccount]);

  const renderPaymentForm = () => {
    if (paymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      return (
        <Box>
          <EHRButton
            dataCy="stripe-onboarding"
            data-testid="stripe-onboarding"
            text={
              // eslint-disable-next-line no-nested-ternary
              isRedirecting ? (
                <>
                  Redirecting you to Stripe, please wait
                  <CircularProgress size={20} style={{ marginLeft: 10, color: 'white' }} />
                </>
              ) : merchantAccount?.['payments/merchantAccount'].onboardingStatus === 'in_progress' ? (
                'Verify Your Identity'
              ) : (
                'Become a Partner'
              )
            }
            color="primary"
            disabled={
              isLoading ||
              isRedirecting ||
              merchantAccount?.['payments/merchantAccount'].onboardingStatus === 'completed' ||
              merchantAccount?.['payments/merchantAccount'].canProcessPayments
            }
            onClick={() => stripeOnboarding()}
          />
          {onboardingStatus === 'in_progress' && (
            <Box>
              <h3 style={{ margin: '1.2rem 0 0.5rem' }}>ID Document Verification Required</h3>
              Please click on the button above and ensure you upload the required documents to verify your identity.
            </Box>
          )}
          {onboardingStatus === 'completed' && (
            <Box>
              <h3 style={{ margin: '1.2rem 0 0.5rem' }}>Documentation upload confirmed by Stripe.</h3>
            </Box>
          )}
        </Box>
      );
    }

    return null;
  };

  return isLoading ? <CircularProgress /> : <>{renderPaymentForm()}</>;
};

export default PaymentManagementSetup;
