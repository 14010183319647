import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import SavePaymentMethod from 'src/components/common/Payment/SavePaymentMethodCustomer';
import { dispatch, RootState } from 'src/rematch';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import SelectAndChargeCard from '../../../components/PatientProfile/CreditCards/SelectAndChargeCard';
import { useStyles } from './SelectOrAddCardForMembership.styles';
import { ISquareCard } from '../../../interfaces/ISquareCard.interfaces';

interface SelectOrAddCardForMembershipProps {
  selectedCardId: string | undefined;
  setSelectedCardId: (cardId: string) => void;
  onSave: (data?: any) => void;
  loading: boolean;
  patientCards: ISquareCard[];
  squareLocationId: string;
  patientId: string;
  onSuccessCardAdd: (data: any) => void;
  buttonText: string;
  showChargeMessage?: boolean;
}

export const SelectOrAddCardForMembership = ({
  selectedCardId,
  setSelectedCardId,
  onSave,
  loading,
  patientCards,
  squareLocationId,
  patientId,
  onSuccessCardAdd,
  buttonText,
  showChargeMessage = true,
}: SelectOrAddCardForMembershipProps) => {
  const classes = useStyles();
  const [seed, setSeed] = useState(1);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { isLoading } = useSelector(({ auth }: RootState) => auth);

  const StartMembershipTabs = () => (
    <div className={classes.tabsContainer}>
      <Button
        className={`${classes.mr10} ${tabIndex === 0 ? classes.activeTab : classes.inactiveTab}`}
        onClick={() => {
          if (!loading) {
            setTabIndex(0);
          }
        }}
      >
        Saved Payment Options
      </Button>
      <Button
        className={tabIndex === 1 ? classes.activeTab : classes.inactiveTab}
        onClick={() => {
          if (!loading) {
            setTabIndex(1);
          }
        }}
      >
        New Card
      </Button>
    </div>
  );

  const handleAddCardSuccess = async (cardId: string, callBack: () => void) => {
    try {
      await onSuccessCardAdd({ cardId });
      callBack();
      // @ts-ignore
      setSeed(crypto.randomUUID());
    } catch (error: any) {
      // @ts-ignore
      setSeed(crypto.randomUUID());
      const errorMessage = error.response?.data?.message;
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: errorMessage ?? 'Something went wrong, failed to create subscription.',
          type: 'error',
        },
      });
    }
  };

  const SuccessElement = () => (
    <Button disabled className={classes.checkoutButton}>
      <>
        Processing
        <CircularProgress size={20} className={classes.processing} />
      </>
    </Button>
  );

  const savePaymentMethod = isLoading ? null : (
    <SavePaymentMethod
      key={seed}
      locationId={squareLocationId}
      patientId={patientId}
      onCardAdded={handleAddCardSuccess}
      buttonText={buttonText}
      loading={loading}
      successElement={<SuccessElement />}
    />
  );

  return (
    <>
      <StartMembershipTabs />
      {tabIndex === 0 ? (
        <SelectAndChargeCard
          selectedCardId={selectedCardId}
          onCardSelect={(e) => {
            setSelectedCardId(e.target.value);
          }}
          buttonText={buttonText}
          patientCards={patientCards}
          loading={loading}
          onCharge={onSave}
          showChargeMessage={showChargeMessage}
        />
      ) : (
        <div>{savePaymentMethod}</div>
      )}
    </>
  );
};
