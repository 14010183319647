import React, { useContext, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { EDIT_INSTRUCTIONS } from 'src/constants/inventory.constants';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { useUpdateCommercialShippingAddress } from 'src/hooks/queries/useInventoryOrders';
import { PORTRAIT_ASCEND, PORTRAIT_FLEX, PORTRAIT_LAUNCH } from 'src/constants/general.constants';
import DeliveryDate from '../../DeliveryDate';
import DeliveryAddress from '../../DeliveryAddress';
import { OrderContext } from '../..';
import OrderConfirmPanel from '../OrderConfirmPanel';
import { useStyles } from './OrderDeliveryOptions.styles';
import OrderAlert from '../../OrderAlert';
import DeliveryDisclaimerModal from './DeliveryDisclaimerModal';

export const OrderDeliveryOptions = ({ isLegacy = false }: { isLegacy?: boolean }) => {
  const classes = useStyles();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const { orderData } = useContext(OrderContext) || {};
  const { commercialShippingAddressId = null, roleName } = orderData || {};
  const { orderData: { deliveryLocation = null, commercialShippingAddress = null } = {} } =
    useContext(OrderContext) || {};

  const isFlexOrLite = roleName === PORTRAIT_FLEX || roleName === PORTRAIT_LAUNCH || roleName === PORTRAIT_ASCEND;

  const { mutateAsync: updateAddress } = useUpdateCommercialShippingAddress();
  const handleAddressSelect = async (selectedAddress: IMedspaLocation) => {
    if (orderData?.id) {
      await updateAddress({ orderId: orderData.id, commercialShippingAddressId: selectedAddress.id });
    }
  };

  const handleDateSelect = () => {
    if (isFlexOrLite && !showDisclaimer) {
      setShowDisclaimer(true);
    }
  };

  return (
    <OrderConfirmPanel title="Delivery">
      <>
        <Typography className={classes.labelTitleMid}>Delivery Address</Typography>
        <DeliveryAddress
          location={deliveryLocation}
          onAddressSelect={handleAddressSelect}
          commercialShippingAddressId={commercialShippingAddressId as number}
          commercialShippingAddress={commercialShippingAddress as any}
          showDropdown={!!commercialShippingAddress}
        />
        <Box margin="15px 0">{!commercialShippingAddress && <OrderAlert text={EDIT_INSTRUCTIONS} />}</Box>
        <Typography className={classes.labelTitleMid}>Preferred Delivery Date (Not Guaranteed)</Typography>
        <DeliveryDate
          className={classes.legacyDatePicker}
          ProgressParams={{ height: 32 }}
          onDateSelect={handleDateSelect}
        />
        {isLegacy && (
          <Typography variant="caption" color="textSecondary" className={classes.dateCaption}>
            *Please note that someone must be able to receive the package <br />
            on the day of the delivery. Deliveries can arrive as early as 8:00am.
          </Typography>
        )}
        <DeliveryDisclaimerModal open={showDisclaimer} onClose={() => setShowDisclaimer(false)} />
      </>
    </OrderConfirmPanel>
  );
};

export default OrderDeliveryOptions;
