// eslint-disable-next-line import/no-unresolved
import kebabcase from 'kebabcase-keys';
import { uniq, get, clone } from 'lodash';
import * as Sentry from '@sentry/react';
import axios from '../utils/axios';
import { dispatch } from '.';
import { compressImage, validatesImageFormat } from '../utils/image.utils';
import Customers from '../services/Customers';
import compile from '../utils/toastMessagesCompiler';
import IVariant, { IVariantItem } from '../interfaces/IVariants';

export const createNewServiceVisitRequest = async ({ patientId }: any) => {
  dispatch({ type: 'newServiceVisit/createNewServiceVisit' });
  try {
    const response = await axios.post(`customers/${patientId}/create-service-visit`, {
      opened: true,
      from_new_ehr: true,
    });
    dispatch({ type: 'patient/updateServiceVisit', payload: response.data });
    dispatch({ type: 'newServiceVisit/createNewServiceVisitSuccess', payload: response.data });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Service visit',
          action: 'created',
        }),
      },
    });
    window.location.href = `/patient/${patientId}/serviceVisit/${response.data.id}`;
  } catch (error) {
    dispatch({ type: 'newServiceVisit/createNewServiceVisitFail' });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'creating',
          element: 'a new service request',
        }),
        type: 'error',
      },
    });
  }
};

export const discardNewServiceVisit = async ({ serviceVisitId, patientId }: any) => {
  await axios.post(`service-visits/${serviceVisitId}/delete`);
  dispatch({ type: 'patient/fetchPatientData', payload: patientId });
};

export const addServiceNoteRequest = async ({ serviceId, body, callback }: any) => {
  try {
    const response = await axios.post(`service-visits/${serviceId}/notes`, body);
    if (response.data.success) {
      callback();
      dispatch({ type: 'newServiceVisit/addServiceNoteSuccess', payload: response.data });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Service note',
            action: 'added',
          }),
        },
      });
      return;
    }
  } catch (error) {
    Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);
  }
  dispatch({
    type: 'snackbar/enqueueSnackBar',
    payload: {
      message: compile('generic.error_message', {
        action: 'adding',
        element: 'the note',
      }),
      type: 'error',
    },
  });
};

const updateServiceVisitModel = (savedData: any, serviceVisit: any) => {
  const oldAnnotations = JSON.parse(JSON.stringify(serviceVisit.annotatedPhotos));

  dispatch({ type: 'newServiceVisit/getServiceVisit', payload: { serviceVisit, savedData, oldAnnotations } });
};

export const getServiceVisitFromPatient = async (
  { serviceVisitId, currentPatientId, errorCallback = () => {} }: any,
  { patient }: any
) => {
  dispatch({ type: 'newServiceVisit/setIsLoadingGetServiceVisitFromPatient', payload: true });
  try {
    if (patient.id && parseInt(patient.id, 10) === parseInt(currentPatientId, 10)) {
      const request = await axios.get(`/service-visits/${serviceVisitId}/current-service-visit`);

      const { serviceVisit } = request.data;
      if (serviceVisit) {
        dispatch({
          type: 'annotations/setInitialLinesAndNotes',
          payload: {
            annotatedPhotos: serviceVisit.annotatedPhotos,
            charges: serviceVisit.charges,
          },
        });
        // Get last session modal id
        // TODO: Review the process of the session
        // think if it is necessary to update the sessionId in the backend before
        // updateServiceVisitModel, Remember method getServiceVisitFromPatient is used by different components
        dispatch({ type: 'newServiceVisit/setIsLoadingProgressingSaving', payload: true });
        const response = await axios.get(`/customers/${currentPatientId}/service-visit-progress/${serviceVisitId}`);

        const savedData = response.data.success ? JSON.parse(response.data.serviceVisitProgress.payload) : {};
        updateServiceVisitModel(savedData, serviceVisit);
        dispatch({ type: 'newServiceVisit/setAllowSaveProgress', payload: true });
        dispatch({ type: 'newServiceVisit/setSynchronizingServiceVisit', payload: false });
        dispatch({ type: 'newServiceVisit/setIsLoadingProgressingSaving', payload: false });
      } else {
        errorCallback();
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.element_not_exists', {
              element: 'The service visit',
            }),
            type: 'error',
          },
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'recovering',
          element: 'the saved data',
        }),
        type: 'error',
      },
    });
  }

  dispatch({ type: 'newServiceVisit/setIsLoadingGetServiceVisitFromPatient', payload: false });
  dispatch({ type: 'newServiceVisit/setIsLoadingProgressingSaving', payload: false });
};

export const fetchServices = async (data: any) => {
  try {
    dispatch({ type: 'newServiceVisit/setIsLoadingServices', payload: true });

    const response = await axios.get('/services', { params: { service_visit_id: data?.contextServiceVisitId } });

    dispatch({ type: 'newServiceVisit/getServices', payload: response.data });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'the services',
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'newServiceVisit/setIsLoadingServices', payload: false });
  }
};

export const uploadPhotosRequest = async ({ files, type, serviceVisitId }: any, { patient }: any) => {
  dispatch({ type: 'newServiceVisit/uploadPhotosLoading', payload: { type, loading: true } });

  for (let index = 0; index < files.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    const validatedFile = await validatesImageFormat(files[index]);

    compressImage({
      file: validatedFile as File,
      onSuccess: async (imageCompressed) => {
        const formData = new FormData();
        formData.append('images[]', imageCompressed);
        formData.append('photo_type', type);
        formData.append('service_visit_id', serviceVisitId);

        try {
          const { photos } = await Customers.uploadPhoto(patient.id, formData);

          photos.forEach((photo: any) => {
            // eslint-disable-next-line no-param-reassign
            photo.photoType = type;
          });

          dispatch({ type: 'patient/uploadPhotosSuccess', payload: photos });
          dispatch({
            type: 'newServiceVisit/uploadPhotosSuccess',
            payload: { newPhotos: photos },
          });
        } catch (error: any) {
          const apiError = error.response?.data?.error;
          const genericError = compile('generic.error_message', {
            action: 'uploading',
            element: 'your sv photo',
          });
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: apiError ? `${genericError} - ${apiError}` : genericError,
              type: 'error',
            },
          });
        } finally {
          dispatch({ type: 'newServiceVisit/uploadPhotosLoading', payload: { type, loading: false } });
        }
      },
      onError: (error: any) => {
        if (process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION') {
          // eslint-disable-next-line no-console
          console.log(error?.message);
        }
        Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);

        dispatch({ type: 'newServiceVisit/uploadPhotosLoading', payload: { type, loading: false } });
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'File format',
              element: 'not supported',
            }),
            type: 'error',
          },
        });
      },
    });
  }
};

const removeDiscount = (checkoutData: any, serviceId: number, serviceName: any, isVariantItem?: boolean) => {
  const checkout = checkoutData;
  if (isVariantItem && checkout.variantsDiscounts[serviceId]) {
    // eslint-disable-next-line no-param-reassign
    delete checkout.variantsDiscounts[serviceId];

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('new_service_visit.remove_discount', { serviceName }),
        type: 'info',
        duration: 10000,
      },
    });
  } else if (checkout.servicesDiscounts[serviceId]) {
    // eslint-disable-next-line no-param-reassign
    delete checkout.servicesDiscounts[serviceId];

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('new_service_visit.remove_discount', { serviceName }),
        type: 'info',
        duration: 10000,
      },
    });
  }

  return checkout;
};

export const saveProgressStep1 = async ({ serviceVisitId, serviceId, services }: any, rootState: any) => {
  const { newServiceVisit } = rootState;
  let selectedServiceIds = [...newServiceVisit.selectedServices];
  const {
    servicesUnits,
    creditServicesUnits,
    variantsUnits,
    creditVariantsUnits,
    totalServicesUnits,
    totalCreditServicesUnits,
  }: any = { ...newServiceVisit };
  let signedStandingOrders = [...newServiceVisit.signedStandingOrders];
  let checkout = clone(newServiceVisit.checkout);

  const service = selectedServiceIds.find((id: number) => serviceId === id);

  if (service) {
    // uncheck
    selectedServiceIds = selectedServiceIds.filter((id: number) => id !== serviceId);
    delete servicesUnits[serviceId];
    delete creditServicesUnits[serviceId];

    services
      .find(({ id }: any) => id === +serviceId)
      .variants.forEach(({ items }: IVariant) => {
        items.forEach((item: IVariantItem) => {
          delete variantsUnits[item.id];
          delete creditVariantsUnits[item.id];
          checkout = removeDiscount(checkout, item.id, item.name, true);
          dispatch({ type: 'newServiceVisit/removeVariantCredit', payload: item.id });
        });
      });
    delete totalServicesUnits[serviceId];
    delete totalCreditServicesUnits[serviceId];

    signedStandingOrders = signedStandingOrders.filter((id: number) => id !== serviceId);
    checkout = removeDiscount(checkout, serviceId, service.name);
    dispatch({ type: 'newServiceVisit/removeCredit', payload: serviceId });
    dispatch({ type: 'annotations/removeAnnotationsRequest', payload: { serviceVisitId, serviceId } });
  } else {
    // check
    selectedServiceIds.push(serviceId);
    servicesUnits[serviceId] = '1';
    creditServicesUnits[serviceId] = '0';
    services
      .find(({ id }: any) => id === +serviceId)
      .variants.forEach(({ items }: IVariant) => {
        items.forEach((item: IVariantItem) => {
          variantsUnits[item.id] = '0';
          creditVariantsUnits[item.id] = '0';
        });
      });
    totalServicesUnits[serviceId] = '1';
    totalCreditServicesUnits[serviceId] = '0';
  }

  let selectedServiceGroupIds = [...newServiceVisit.selectedServiceGroups];

  const selectedServicesForGroups = newServiceVisit.services.filter(({ id }: any) => selectedServiceIds.includes(id));

  selectedServiceGroupIds = uniq(selectedServicesForGroups.map(({ serviceGroupId }: any) => serviceGroupId));

  const newProductsSelected = selectedServiceIds.map((selectedServiceId: string) => {
    const productService = newServiceVisit.productsSelected.find((product: any) => product.id === selectedServiceId);
    return productService || { ...services.find(({ id }: any) => id === selectedServiceId), assets: [] };
  });

  const payload = {
    servicesUnits,
    creditServicesUnits,
    variantsUnits,
    creditVariantsUnits,
    totalServicesUnits,
    totalCreditServicesUnits,
    selectedServices: selectedServiceIds,
    selectedServiceGroups: selectedServiceGroupIds,
    productsSelected: newProductsSelected,
    signedStandingOrders,
    checkout,
  };

  dispatch({ type: 'newServiceVisit/selectService', payload });
};

export const saveNewServiceVisit = async ({ serviceVisitId, body, charges, callback }: any) => {
  try {
    const response = await axios.post(`/service-visits/${serviceVisitId}/photo-services-handler-new`, {
      photos: body,
      charges,
    });

    if (get(response, 'data.serviceVisit')) {
      dispatch({ type: 'newServiceVisit/saveNewServiceVisitSuccess', payload: response.data.serviceVisit });
    }
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: '',
          element: '',
        }),
        type: 'error',
      },
    });
  } finally {
    callback();
  }
};

export const saveNotesForServiceVisit = async ({ serviceVisitId, body, callback }: any) => {
  try {
    await axios.post(`/service-visits/${serviceVisitId}/notes`, { ...body });
    dispatch({ type: 'newServiceVisit/saveNewServiceVisitSuccess', payload: body });
    if (callback) {
      callback();
    }
  } catch (error) {
    Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);
  }
};

export const checkout = async ({
  checkout: checkoutData,
  successCallback,
  payWithCardForCharge,
  errorCallback,
  amount,
}: any) => {
  dispatch({ type: 'newServiceVisit/processingCheckout', payload: true });

  try {
    const response = await axios.post(
      `service_visits/${checkoutData.serviceVisitId}/checkout`,
      kebabcase({ ...checkoutData }, { deep: true })
    );
    if (get(response, 'data.success')) {
      if (amount === 0) {
        await axios.post(`/v3/service_visits/${checkoutData.serviceVisitId}/post_checkout_movements`, {});
      } else if (payWithCardForCharge) {
        await axios.post(`/v3/customers/${checkoutData.patientId}/charge_for_services_and_products`, {
          credit_card_id: payWithCardForCharge,
          service_visit_id: checkoutData.serviceVisitId,
        });
        successCallback?.();
      } else {
        successCallback?.();
      }
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'processing',
            element: 'your checkout',
          }),
          type: 'error',
        },
      });
      if (errorCallback) {
        errorCallback(response.data?.error);
      }
    }
  } catch (error) {
    if (errorCallback) {
      errorCallback(error.response?.data?.error);
    }
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'processing',
          element: 'your checkout',
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'newServiceVisit/processingCheckout', payload: false });
  }
};

export const refundUndo = async ({
  checkout: checkouData,
  finallyCallback,
  processedInSquare,
  isAccountChargeServiceVisit,
}: any) => {
  const showSuccessMessage = (element: string) => {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element,
          action: '',
        }),
      },
    });
  };

  try {
    if (processedInSquare) {
      const response = await axios.post(`service_visits/${checkouData.serviceVisitId}/refund`, checkouData);

      if (get(response, 'data.success')) {
        showSuccessMessage('Refund');
      }
    } else {
      const response = await axios.post(
        `/v3/service_visits/${checkouData.serviceVisitId}/undo_post_checkout_movements`,
        {}
      );

      if (response.status === 200) {
        showSuccessMessage('Undo');
      }
    }

    window.location.href = isAccountChargeServiceVisit
      ? `/patient/${checkouData.patientId}/retailCheckout`
      : `/patient/${checkouData.patientId}/serviceVisit/${checkouData.serviceVisitId}`;
  } catch (error) {
    dispatch({ type: 'newServiceVisit/processRefundFail' });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'processing',
          element: `your ${processedInSquare ? 'refund' : 'undo'}`,
        }),
        type: 'error',
      },
    });
  } finally {
    finallyCallback();
  }
};

export const squareResponse = async ({ transactionInfo, serviceVisitId, finallyCallback }: any) => {
  const { error_code: errorCode } = transactionInfo;

  try {
    await axios.post(`service_visits/${serviceVisitId}/update_checkout`, { square_error_code: errorCode });
    localStorage.setItem('squareTransaction', 'done');
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'updating',
          element: 'the transaction',
        }),
        type: 'error',
      },
    });
  } finally {
    finallyCallback();
  }
};

export const deleteAnnotated = async ({ service_annotated_photo_id: serviceAnnotatedPhotoId, photoId }: any) => {
  try {
    await axios.post(`/service-visits/delete-annotated`, { serviceAnnotatedPhotoId });
    dispatch({ type: 'newServiceVisit/deleteAnnotatedSuccess', payload: photoId });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Annotations',
          action: 'deleted',
        }),
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: '',
          element: '',
        }),
        type: 'error',
      },
    });
  }
};

export const fetchCheckout = async ({ serviceVisitId, interval, successCallback = () => {} }: any) => {
  dispatch({ type: 'newServiceVisit/processCheckout' });
  try {
    if (!interval) {
      dispatch({ type: 'newServiceVisit/setIsLoadingFetchCheckout', payload: true });
    }
    const response = await axios.get(`service_visits/${serviceVisitId}/fetch_checkout`);

    dispatch({ type: 'newServiceVisit/processCheckoutSuccess', payload: response.data });
    successCallback();
  } catch (error) {
    dispatch({ type: 'newServiceVisit/processCheckoutFail' });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'getting',
          element: 'your checkout information',
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'newServiceVisit/setIsLoadingFetchCheckout', payload: false });
  }
};

export const submitReviewRequest = async ({ serviceVisitId, successCallback }: any) => {
  try {
    const body = { opened: false };
    const response = await axios.post(`/service-visits/${serviceVisitId}/update`, body);
    if (response.data.success) {
      dispatch({ type: 'patient/markServiceVisitAsClosed' });
      successCallback();
    }

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Service visit',
          action: 'submitted',
        }),
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'submitting',
          element: 'the service visit',
        }),
        type: 'error',
      },
    });
  }
};

export const fetchDiscounts = async () => {
  try {
    const response = await axios.get('/discounts');
    dispatch({ type: 'newServiceVisit/getDiscountsSuccess', payload: response.data.discounts });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'discounts',
        }),
        type: 'error',
      },
    });
  }
};

export const payStaging = async ({ serviceVisitId, patientId, amount, errorCallback }: any) => {
  try {
    await (amount === 0
      ? axios.post(`/v3/service_visits/${serviceVisitId}/post_checkout_movements`, {})
      : axios.post(`/service_visits/${serviceVisitId}/pay_staging`));
    dispatch({ type: 'newServiceVisit/fetchCheckout', payload: { serviceVisitId } });
    dispatch({ type: 'referrals/getRedeemedCustomers', payload: { customerId: patientId } });
  } catch (error) {
    if (errorCallback) {
      errorCallback();
    }
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: '',
          element: '',
        }),
        type: 'error',
      },
    });
  }
};

export const checkoutServiceVisitWithCard = async ({
  serviceVisitId,
  creditCardId,
  patientId,
  successCallback,
  errorCallback,
}: {
  patientId: string;
  serviceVisitId: string;
  creditCardId: string;
  successCallback?: () => void;
  errorCallback?: (error: any) => void;
}) => {
  dispatch({ type: 'newServiceVisit/processingCheckout', payload: true });

  try {
    await axios.post(`/v3/customers/${patientId}/charge_for_services_and_products`, {
      credit_card_id: creditCardId,
      service_visit_id: serviceVisitId,
    });
    successCallback?.();
  } catch (error) {
    if (errorCallback) {
      errorCallback(error.response?.data?.error);
    }
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'processing',
          element: 'your checkout',
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'newServiceVisit/processingCheckout', payload: false });
  }
};
