import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, MenuItem, FormControl, Menu, CircularProgress } from '@material-ui/core';
import { debounce, snakeCase } from 'lodash';
import moment from 'moment';
import Page from 'src/components/common/Page';
import { EHRButton, EHRCheckbox, EHRMultipleSelect, EHRMultipleSelectorProps, EHRSelect } from 'src/components/ui/v1';
import { useUpdateEffect } from 'src/hooks/useUpdateEffect';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { ONBOARDING_STEPS, ONBOARDING_VIDEOS, SHOW_SUCCESS_MESSAGE } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { VideoModal } from 'src/pages/MedspaAdmin/Onboarding/components/VideoModal';
import EmptyPage from 'src/components/common/EmptyPage';
import { VideoBanner } from 'src/pages/MedspaAdmin/Onboarding/components/VideoBanner';
import { MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG } from 'src/routes/medspaAdminRoutes';
import { useCurrentUserGroup } from 'src/hooks/queries/useUserGroups';
import InputSearch from '../../common/InputSearch';
import { ICustomerViewParams } from '../../../interfaces/CustomerParams';
import {
  MEDSPA_ADMIN_ROLE,
  CLEARED_STATE,
  INCOMPLETE_STATE,
  LEAD_STATE,
  PENDING_RECLEAR_STATE,
  TO_CLEAR_STATE,
  TO_RECLEAR_STATE,
  STRIPE_PAYMENT_PROCESSOR,
} from '../../../constants/general.constants';
import NewPatientForm from './NewPatientForm';
import BulkUpload from './BulkUpload';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_CUSTOMER } from '../../../constants/actions.constants';
import TablePaginator from '../../common/Table/TablePaginator';
import { useMedspaPatients } from '../../../hooks/queries/useMedspaPatients';
import {
  MedicalProfileUpdatedIcon,
  NewMessagesIcon,
  PhotoRequestedIcon,
  UnseenPhotosIcon,
} from '../../../constants/patients/columns.constants';
import { useMedspaPractitioners, useUpdateMedspaAdminOnboardingProgress } from '../../../hooks/queries/useMedspaAdmins';
import { usePatientMedspaStyles } from './PatientMedspaStyles';
import { IPatientView } from '../../../types/Patient';
import { CustomLink } from '../../common/Custom/CustomLink';
import { ROUTES } from '../../../constants/routes.constants';

const INITIAL_ORDER_BY = 'next_appointment';
const INITIAL_DIRECTION = 'ASC';

const initialParams = {
  status: '',
  page: 1,
  search: '',
  hasRequestedPhotos: false,
  createdAsLead: false,
  sort: INITIAL_ORDER_BY,
  sortDirection: INITIAL_DIRECTION,
  practitionerIds: [],
};

export const PATIENT_STATUS_OPTS = {
  all_status: 'All status',
  [snakeCase(LEAD_STATE)]: 'Lead',
  [snakeCase(INCOMPLETE_STATE)]: 'Incomplete',
  [snakeCase(TO_CLEAR_STATE)]: 'To clear',
  [snakeCase(TO_RECLEAR_STATE)]: 'To reclear',
  [snakeCase(CLEARED_STATE)]: 'Cleared',
  [snakeCase(PENDING_RECLEAR_STATE)]: 'Pending reclear',
};

const ALL_PATIENT_COLUMNS = [
  { field: 'id', title: 'id' },
  {
    field: 'firstName',
    title: 'First name',
    sortable: true,
    sortField: 'first_name',
    render: (value: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>{value}</CustomLink>
    ),
  },
  {
    field: 'lastName',
    title: 'Last name',
    sortable: true,
    sortField: 'last_name',
    render: (value: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>{value}</CustomLink>
    ),
  },
  {
    field: 'providerLabelings',
    width: '200px',
    title: 'Provider(s)',
    sortable: false,
    sortField: 'provider_labelings',
    render: (value: []) => value?.map((providerName: string) => <Box key={providerName}>{providerName}</Box>) || null,
  },
  { field: 'email', title: 'Email' },
  {
    field: '',
    title: 'Updates',
    render: (v: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>
        {row.unreadMessagesCount > 0 && <NewMessagesIcon count={row.unreadMessagesCount} />}
        {row.unseenPhotosCount > 0 && <UnseenPhotosIcon count={row.unseenPhotosCount} />}
        {row.needsDoctorReview && <MedicalProfileUpdatedIcon />}
        {row.hasRequestedPhotos && <PhotoRequestedIcon />}
      </CustomLink>
    ),
  },
  {
    field: 'nextAppointment',
    title: 'Next appointment',
    sortable: true,
    sortField: 'next_appointment',
    render: (value: string) => (value ? moment(value).format('MM/DD/YYYY HH:mm') : ''),
  },
  { field: 'visits', title: 'Visits', sortable: true, sortField: 'visits' },
  {
    field: 'status',
    title: 'Status',
    sortable: true,
    sortField: 'status',
    render: (value: string) => PATIENT_STATUS_OPTS[value],
  },
  { field: 'phone', title: 'Phone' },
  {
    field: 'createdAt',
    title: 'Created at',
    width: '150px',
    sortable: true,
    sortField: 'created_at',
    render: (value: string) => moment(value).format('MM/DD/YYYY HH:mm'),
  },
];

const PatientsPage = () => {
  const classes = usePatientMedspaStyles();
  const { data: userGroup, isLoading: isLoadingUserGroup } = useCurrentUserGroup();
  const { permissions, roleName } = useSelector(({ auth }: any) => auth);
  const isSpaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const { userGroupId } = useSelector(({ auth }: any) => auth);
  const [newPatientModalOpen, setNewPatientModalOpen] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [uploadType, setUploadType] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [queryParams, setQueryParams] = useState<ICustomerViewParams>(initialParams);
  const [withPhotoRequestChecked, setWithPhotoRequestChecked] = useState<boolean>(initialParams.hasRequestedPhotos);
  const { data: assignedPractitioners = [] } = useMedspaPractitioners(isSpaAdmin);
  const [hasPatients, setHasPatients] = useState<boolean>(false);

  const {
    isLoading,
    isFetching,
    data: { patients, totalCount },
    fetchNextPage,
    isError,
    refetch,
  } = useMedspaPatients(userGroupId, queryParams);

  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const {
    FooterComponent,
    DoneFooterComponent,
    onboardingEnabled,
    allStepsCompleted,
    isLoading: isLoadingOnboarding,
  } = useMedspaAdminOnboarding(ONBOARDING_STEPS.PATIENTS_MANAGEMENT_NAME);

  const showMedspaAdminNewPatientButton = useMemo(
    () => userGroup?.paymentProcessor !== STRIPE_PAYMENT_PROCESSOR || userGroup?.hasMerchantAccount,
    [userGroup]
  );

  const onChangeSortBy = (column: string, direction: string) => {
    setQueryParams({ ...queryParams, sort: column, sortDirection: direction });
  };

  const onChangeStatus = (status: string) => {
    const newStatus = status === 'all_status' ? '' : status;

    setQueryParams({
      ...queryParams,
      status: newStatus,
      hasRequestedPhotos: withPhotoRequestChecked,
    });
  };

  useEffect(() => {
    if (totalCount > 0) {
      setHasPatients(true);
    }
  }, [totalCount]);

  useUpdateEffect(() => {
    setQueryParams({
      ...queryParams,
      hasRequestedPhotos: withPhotoRequestChecked,
    });
  }, [withPhotoRequestChecked]);

  const debounceCall = (query: string, params: ICustomerViewParams) => {
    setQueryParams({
      ...params,
      search: query,
    });
  };

  const doSearch = useMemo(
    () => debounce((query: string, params: ICustomerViewParams) => debounceCall(query, params), 1000),
    []
  );

  const onInputChange = (event: any) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    doSearch(newSearchQuery, queryParams);
  };

  const handleOpenBulkModal = (type: string) => {
    setShowBulkModal(true);
    setUploadType(type);
  };

  const handleBulkUploadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type: string) => {
    setAnchorEl(null);
    handleOpenBulkModal(type);
  };

  const handlePractitionerChange: EHRMultipleSelectorProps['onChange'] = (values) => {
    const newValues = values as number[];
    setQueryParams({ ...queryParams, practitionerIds: newValues });
  };

  const updateProgressOnSuccess = async () => {
    if (onboardingEnabled && !allStepsCompleted) {
      const resp = await updateProgress({
        stepName: ONBOARDING_STEPS.PATIENTS_MANAGEMENT_NAME,
        percentage: 100,
      });

      if (resp.completed && resp?.message === SHOW_SUCCESS_MESSAGE) {
        setOpenEhrOnboardingSuccessModal(true);
      }
    }
  };

  if (isLoading || isLoadingOnboarding || isLoadingUserGroup) {
    return (
      <Page title="Patients">
        <Box textAlign="center" width="100%" padding="100px 0">
          <CircularProgress />
        </Box>
      </Page>
    );
  }

  if (!isLoading && !hasPatients) {
    return (
      <>
        {onboardingEnabled && (
          <div style={{ padding: '2rem' }}>
            <VideoBanner title="How to add new patients?" onClick={() => setShowVideoModal(true)} />
          </div>
        )}
        <EmptyPage title="Patients">
          <h1>Welcome to Your Patients Management</h1>
          <p>It looks like you haven’t added patients yet.</p>
          <p>Click below to add your first patient.</p>
          <Box marginTop="24px" display="flex" gridGap="1rem">
            <Box>
              <EHRButton
                dataCy="btn-bulk-upload"
                color="default"
                text="Bulk Upload"
                onClick={handleBulkUploadClick}
                disabled={!showMedspaAdminNewPatientButton}
              />
              <Menu
                id="bulk-upload-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleMenuItemClick('provider')}>Assign to a Provider</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('medspa')}>Assign to MedSpa</MenuItem>
              </Menu>
            </Box>
            {Boolean(permissions?.length > 0) && hasAccessTo(CREATE_CUSTOMER, permissions) && (
              <Box>
                <EHRButton
                  dataCy="btn-new-patient"
                  color="primary"
                  text="New Patient"
                  onClick={() => setNewPatientModalOpen(true)}
                  disabled={!showMedspaAdminNewPatientButton}
                />
              </Box>
            )}
          </Box>

          {!showMedspaAdminNewPatientButton && (
            <p style={{ marginTop: '2rem' }}>
              Contact your Medspa Admin to finish Payment Management Setup in Onboarding Step 1 to create patients
            </p>
          )}

          <NewPatientForm
            setShowNewPatientModal={setNewPatientModalOpen}
            showNewPatientModal={newPatientModalOpen}
            onSuccessCallback={updateProgressOnSuccess}
          />
          <BulkUpload
            uploadType={uploadType}
            showBulkModal={showBulkModal}
            setShowBulkModal={setShowBulkModal}
            onSuccessCallback={updateProgressOnSuccess}
          />
        </EmptyPage>

        <FooterComponent
          currentStep="Patients"
          nextStep="Appointment Types"
          urlToNextStep={MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG}
        />

        <VideoModal
          open={showVideoModal}
          onClose={() => setShowVideoModal(false)}
          url={ONBOARDING_VIDEOS.PATIENTS_MANAGEMENT_NAME}
        />
      </>
    );
  }

  return (
    <>
      <Page title="Patients">
        <Box width="100%">
          {onboardingEnabled && (
            <div style={{ paddingBottom: '2rem' }}>
              <VideoBanner title="How to add new patients?" onClick={() => setShowVideoModal(true)} />
            </div>
          )}
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" gridGap="1rem">
            <Box display="flex" flexWrap="wrap" gridGap="1rem">
              <Box className={classes.patientHeaderFormControlContainer} style={{ minWidth: '296px' }}>
                <InputSearch
                  placeholder="Search..."
                  onChangeValue={onInputChange}
                  value={searchQuery}
                  isLoading={isLoading}
                />
              </Box>
              <Box
                data-cy="stateSelect"
                style={{ width: '150px' }}
                className={classes.patientHeaderFormControlContainer}
              >
                <FormControl fullWidth variant="outlined">
                  <EHRSelect
                    dataCy="select-status"
                    onChange={onChangeStatus}
                    id="state"
                    value={queryParams.status === '' ? 'all_status' : queryParams.status}
                    fullWidth
                  >
                    {Object.entries(PATIENT_STATUS_OPTS).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </EHRSelect>
                </FormControl>
              </Box>
              <Box
                data-cy="practitionerIdSelect"
                style={{ width: '10.625rem' }}
                className={classes.patientHeaderFormControlContainer}
              >
                <FormControl fullWidth variant="outlined">
                  <EHRMultipleSelect
                    dataCy="select-providers"
                    placeholder="All providers"
                    id="practitionerId"
                    value={queryParams.practitionerIds ?? []}
                    onChange={handlePractitionerChange}
                    options={(assignedPractitioners || [])
                      .slice()
                      .sort((a, b) => a.firstName.localeCompare(b.firstName))
                      .map((value) => ({ name: `${value.firstName} ${value.lastName}`, value: value.id }))}
                  />
                </FormControl>
              </Box>
              <Box className={classes.patientHeaderFormControlContainer}>
                <EHRCheckbox
                  dataCy="checkbox-with-photo-request"
                  label="With photo request"
                  checked={withPhotoRequestChecked}
                  onChange={() => setWithPhotoRequestChecked(!withPhotoRequestChecked)}
                  value="order"
                />
              </Box>
            </Box>
            <Box display="flex" gridGap="1rem" flexWrap="wrap" className={classes.patientHeaderActionButtonsContainer}>
              <Box>
                <EHRButton
                  dataCy="btn-bulk-upload"
                  color="default"
                  text="Bulk Upload"
                  onClick={handleBulkUploadClick}
                  disabled={!showMedspaAdminNewPatientButton}
                />
                <Menu
                  id="bulk-upload-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleMenuItemClick('provider')}>Assign to a Provider</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('medspa')}>Assign to MedSpa</MenuItem>
                </Menu>
              </Box>

              {Boolean(permissions?.length > 0) && hasAccessTo(CREATE_CUSTOMER, permissions) && (
                <Box>
                  <EHRButton
                    dataCy="btn-new-patient"
                    color="primary"
                    text="New Patient"
                    onClick={() => setNewPatientModalOpen(true)}
                    disabled={!showMedspaAdminNewPatientButton}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {!showMedspaAdminNewPatientButton && (
            <p>Finish Payment Management Setup in Onboarding Step 1 to create patients</p>
          )}
          <Box style={{ marginTop: '25px' }}>
            <div className={classes.infiScrollContainer}>
              <TablePaginator
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                data={patients}
                columns={ALL_PATIENT_COLUMNS}
                onChangeSortBy={onChangeSortBy}
                total={totalCount}
                fetchNextPage={fetchNextPage}
              />
            </div>
          </Box>
        </Box>
        <NewPatientForm setShowNewPatientModal={setNewPatientModalOpen} showNewPatientModal={newPatientModalOpen} />
        <BulkUpload
          uploadType={uploadType}
          showBulkModal={showBulkModal}
          setShowBulkModal={setShowBulkModal}
          onSuccessCallback={refetch}
        />
      </Page>

      <FooterComponent
        currentStep="Patients"
        nextStep="Appointment Types"
        urlToNextStep={MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG}
      />

      {openEhrOnboardingSuccessModal && <DoneFooterComponent />}

      <VideoModal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        url={ONBOARDING_VIDEOS.PATIENTS_MANAGEMENT_NAME}
      />
    </>
  );
};

export default PatientsPage;
