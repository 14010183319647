import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import ServiceVisit from '../../services/ServiceVisit';
import { SERVICE_VISIT_CUSTOMER_STANDING_ORDERS, SERVICE_VISIT_STANDING_ORDERS } from '../../constants/reactQuery.keys';

/* Get service visit checkout object
 * Params: id* (service visit id)
 */
export function useCustomerStandingOrders(id: number, enabled: boolean) {
  // TODO: Create a new endpoint
  const response = useQuery(
    [SERVICE_VISIT_CUSTOMER_STANDING_ORDERS, id],
    () => ServiceVisit.customerStandingOrder(id),
    {
      refetchOnWindowFocus: true, // For multi-tab purposes
      staleTime: 5000, // Only refetch if data is older than 5 seconds
      keepPreviousData: true,
      enabled,
      initialData: [],
      onError: () =>
        useReactQueryOnErrorHandler(
          compile('generic.error_message', { action: 'fetching', element: 'standing orders' })
        ),
    }
  );

  return {
    ...response,
    data: response.data?.customerStandingOrders || [],
  };
}

/* Get service visit checkout object
 * Params: id* (service visit id)
 */
export function useServiceVisitStandingOrders(id: number, enabled: boolean) {
  const response = useQuery([SERVICE_VISIT_STANDING_ORDERS, id], () => ServiceVisit.serviceVisitStandingOrder(id), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    staleTime: 5000, // Only refetch if data is older than 5 seconds
    keepPreviousData: true,
    enabled,
    initialData: { serviceVisitStandingOrders: [] },
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'standing orders' })),
  });

  return {
    ...response,
    data: response.data?.serviceVisitStandingOrders || [],
  };
}

export const useSignServiceVisitStandingOrder = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => ServiceVisit.signServiceVisitStandingOrder(id, params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([SERVICE_VISIT_STANDING_ORDERS, id]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};
