import API_ROUTES, { MEDSPA_CARD_CREATE, MEDSPA_SETUP_INTENT } from 'src/constants/apiRoutes.constants';
import { PAYMENT_USER_ERROR } from 'src/constants/square.constants';
import Api from './Api';

export interface CustomerOptions {
  customerId: number;
  userGroupId?: never;
}
export interface MedspaOptions {
  userGroupId: number;
  customerId?: never;
}

export type PaymentUserOptions = CustomerOptions | MedspaOptions;

export interface StripeSetupIntent {
  success: boolean;
  providerType: string;
  externalPaymentId: string;
  clientSecret: string;
  merchantAccountId: string;
  isStandardAccount: boolean;
}

export interface StripePaymentSetupOptions {
  setupIntentId: string;
  paymentMethodId: string;
  last4?: string | null;
  brand?: string | null;
  expMonth?: number | null;
  expYear?: number | null;
}

export interface StripePaymentSetup {
  success: boolean;
  paymentMethodId: string;
}

export const Payments = {
  async savePaymentMethod({
    customerId,
    userGroupId,
    ...params
  }: StripePaymentSetupOptions & PaymentUserOptions): Promise<StripePaymentSetup> {
    if (customerId) {
      return Api.post(API_ROUTES.CUSTOMER_SAVE_PAYMENT_METHOD(customerId), params);
    }

    if (userGroupId) {
      return Api.post(MEDSPA_CARD_CREATE(userGroupId.toString()), params);
    }

    throw new Error(PAYMENT_USER_ERROR);
  },

  async createPaymentIntent({ customerId, userGroupId }: PaymentUserOptions): Promise<StripeSetupIntent> {
    if (customerId) {
      return Api.post(API_ROUTES.CUSTOMER_CREATE_PAYMENT_INTENT(customerId), {});
    }

    if (userGroupId) {
      return Api.post(MEDSPA_SETUP_INTENT(userGroupId.toString()), {});
    }

    throw new Error(PAYMENT_USER_ERROR);
  },
};

export default Payments;
