import React from 'react';
import { Box, Chip, FormControlLabel, Radio, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { IPatientBasicInfo } from 'src/types/Patient';
import { SQUARE_PAYMENT_PROCESSOR, STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { useMedspaLocations } from 'src/hooks/queries/medspaAdmins/useMedspaLocations';
import { IMedspaLocation, SerializerPaymentLocation } from 'src/interfaces/ILocation';
import { STRIPE_STATUS } from 'src/constants/stripe.constants';
import { useStyles } from './ServiceVisitCheckoutContainer.styles';
import { formatNumber } from '../../utils/checkout.utils';

import { ISquareCard } from '../../interfaces/ISquareCard.interfaces';
import CreditCardIcon from '../../components/PatientProfile/CreditCards/CreditCardIcon';
import { CARD_CHARGE_TYPE, CASH_CHARGE_TYPE, SQUARE_CHARGE_TYPE, STRIPE_CHARGE_TYPE } from './RemainingToPay';

interface PaymentOptionsProps {
  chargeType: string;
  setChargeType: (chargeType: string) => void;
  cashAmount: string | undefined;
  setCashAmount: (chargeType: string) => void;
  total: number;
  cards: ISquareCard[];
  selectedCard: ISquareCard | undefined;
  setSelectedCard: (card: any) => void;
  patient: IPatientBasicInfo;
  patientPaymentProcessor: string;
  medspaLocationId: string | number | null | undefined;
  paymentTransactionStatus?: string;
  paymentTransactionLastErrorMessage?: string;
}

export const PaymentOptions = ({
  chargeType,
  setChargeType,
  cashAmount,
  setCashAmount,
  total,
  cards,
  selectedCard,
  setSelectedCard,
  patient,
  patientPaymentProcessor = 'square',
  paymentTransactionStatus,
  paymentTransactionLastErrorMessage,
  medspaLocationId,
}: PaymentOptionsProps) => {
  const styles = useStyles();
  const history = useHistory();
  const patientId = patient.id;
  const {
    data: { medspaLocations = [] },
  } = useMedspaLocations();

  const medspaLocation = medspaLocations.find((location: IMedspaLocation) => location.id === medspaLocationId);
  const stripePaymentLocation = medspaLocation?.paymentLocations?.find(
    (pl: SerializerPaymentLocation) => pl.providerType === STRIPE_PAYMENT_PROCESSOR
  );

  const onCardSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardId = e.target.value;
    const userSelectedCard = cards.find((card) => card.id === cardId);
    setSelectedCard(userSelectedCard);
  };

  const navigateToAddCard = () => {
    history.push(`/patient/${patientId}/creditCards`);
  };

  return (
    <Box className={styles.paymentOptionContainer}>
      <h2>Payment Options</h2>
      <Box>
        <Chip
          key={CARD_CHARGE_TYPE}
          label="Card on File"
          className={chargeType === CARD_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => setChargeType(CARD_CHARGE_TYPE)}
        />
        {patientPaymentProcessor === SQUARE_PAYMENT_PROCESSOR && (
          <Chip
            key={SQUARE_CHARGE_TYPE}
            label="Charge with Square"
            className={chargeType === SQUARE_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
            onClick={() => setChargeType(SQUARE_CHARGE_TYPE)}
          />
        )}
        {patientPaymentProcessor === STRIPE_PAYMENT_PROCESSOR && stripePaymentLocation?.externalReaderId && (
          <Chip
            key={STRIPE_CHARGE_TYPE}
            label="Charge with Stripe"
            className={chargeType === STRIPE_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
            onClick={() => setChargeType(STRIPE_CHARGE_TYPE)}
          />
        )}
        <Chip
          key={CASH_CHARGE_TYPE}
          label="Cash"
          className={chargeType === CASH_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => setChargeType(CASH_CHARGE_TYPE)}
        />
      </Box>
      {paymentTransactionStatus === STRIPE_STATUS.FAILED && (
        <Box className={styles.errorContainer}>
          <p>There was an error processing your payment. Please try again.</p>
          {paymentTransactionLastErrorMessage && (
            <p>
              Error: <b>{paymentTransactionLastErrorMessage}</b>
            </p>
          )}
        </Box>
      )}
      {chargeType === CARD_CHARGE_TYPE &&
        (cards.length ? (
          <>
            {cards.map((card: ISquareCard) => (
              <div>
                <FormControlLabel
                  value={card.id}
                  key={card.id}
                  // @ts-ignore
                  control={<Radio color="primary" checked={card.id === selectedCard?.id} onClick={onCardSelect} />}
                  label={
                    <div className={styles.cardName}>
                      Card ending in {card.last4}
                      <span className={styles.cardIcon}>
                        <CreditCardIcon cardBrand={card.cardBrand} />
                      </span>
                    </div>
                  }
                />
              </div>
            ))}
          </>
        ) : (
          <div className={styles.noCardsContainer}>
            <div>No cards on file</div>
            <button type="button" className={styles.noCardButton} onClick={navigateToAddCard}>
              Add a card
            </button>
          </div>
        ))}
      {chargeType === SQUARE_CHARGE_TYPE && <p>You will be re-directed to Square page to finish the checkout.</p>}
      {chargeType === CASH_CHARGE_TYPE && (
        <>
          <p>Enter the amount you received from your customer.</p>
          <NumberFormat
            decimalScale={2}
            fixedDecimalScale
            autoComplete="off"
            placeholder="$"
            customInput={TextField}
            variant="outlined"
            label="$"
            value={cashAmount}
            onChange={(e) => {
              e.preventDefault();
              setCashAmount(e.target.value);
            }}
            helperText={
              cashAmount && +cashAmount < total
                ? `Warning: Cash amount is less than the total to pay ($${formatNumber(total)})`
                : undefined
            }
            InputProps={{
              className: cashAmount && +cashAmount < total ? styles.warningInput : undefined,
            }}
          />
        </>
      )}
    </Box>
  );
};
