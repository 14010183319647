export enum AppointmentStatusEnum {
  RESERVED = 'reserved',
  CREATED_FROM_ACUITY = 'created_from_acuity',
  CREATED = 'created',
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
  RESCHEDULED = 'rescheduled',
  COMPLETED = 'completed',
  PENDING_CONFIRMATION = 'pending_confirmation',
  // More actions:
  EMAIL_SENT = 'email_sent',
  EMAIL_NOT_SENT = 'email_not_sent',
  SMS_SENT = 'sms_sent',
  SMS_NOT_SENT = 'sms_not_sent',
  CHARGED = 'charged',
}
