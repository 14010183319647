import React, { useEffect, useState } from 'react';
import { Box, Grid, Modal, Typography } from '@material-ui/core';
import { EHRButton, EHRTypography } from 'src/components/ui/v1';
import FeeTypesForm from 'src/components/FeeTypes/FeeTypesForm';
import { useMerchantAccount } from 'src/hooks/queries/medspaAdmins/useMerchantAccount';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import { Button } from '../../../components/common/Button';
import useStyles from './index.styles';
import { useUpdateUserGroup } from '../../../hooks/queries/useUserGroups';
import { formatNumber } from '../../../utils/checkout.utils';

interface DepositsAndFeesProps {
  userGroup: IUserGroup | undefined;
  refetchUserGroup: () => void;
}

const DepositsAndFees: React.FC<DepositsAndFeesProps> = ({ userGroup, refetchUserGroup }) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [cancellationFee, setCancellationFee] = useState(0);
  const [depositFee, setDepositFee] = useState(0);
  const feeTypesConfigured = [cancellationFee ? 'Cancellation fee' : null, depositFee ? 'Deposit fee' : null].filter(
    (type) => !!type
  );
  const feeTypesConfiguredValues = cancellationFee + depositFee;

  const { mutateAsync: updateGroup, isLoading: isUpdating } = useUpdateUserGroup();

  const { data: merchantAccount } = useMerchantAccount(userGroup?.id ?? 0);

  const onboardingStatus = merchantAccount?.['payments/merchantAccount'].onboardingStatus;

  useEffect(() => {
    setCancellationFee(+(userGroup?.cancellationFee || 0));
    setDepositFee(+(userGroup?.depositFee || 0));
  }, [updateGroup]);

  const updateDepositsAndFees = async () => {
    if (userGroup) {
      const data = {
        id: userGroup.id,
        depositFee,
        cancellationFee,
      };
      await updateGroup(data);
    }

    setOpenModal(false);
    refetchUserGroup();
  };

  return (
    <Box>
      <Grid container direction="row" className={classes.parentGrid}>
        <Grid item xs={8}>
          <EHRTypography variant="readonly" dataCy="medspa-setting-deposit-fee-label">
            Deposits and Fees
          </EHRTypography>
          <EHRTypography variant="description" dataCy="medspa-setting-deposit-fee-description">
            Charge deposit or cancellation fees automatically
          </EHRTypography>
        </Grid>
        <Grid item xs={4} container direction="row">
          <Grid item xs={6}>
            <EHRTypography variant="description" dataCy="medspa-setting-deposit-fee-feeTypeDescription">
              {feeTypesConfigured.join(', ')}
            </EHRTypography>
            {feeTypesConfiguredValues ? (
              <Typography>{`$ ${formatNumber(feeTypesConfiguredValues)}`}</Typography>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6} className={classes.gridButton}>
            <Box>
              {userGroup?.paymentProcessor === STRIPE_PAYMENT_PROCESSOR && onboardingStatus !== 'completed' ? (
                <EHRTypography variant="description" dataCy="medspa-setting-deposit-fee-stripe-onboarding-incomplete">
                  Please complete the Payment Management Set-Up section to enable deposits and fees in Step 1 in
                  Onboarding Page.
                </EHRTypography>
              ) : (
                <EHRButton
                  text="Edit"
                  onClick={() => setOpenModal(true)}
                  color="primary"
                  dataCy="btn-edit-deposit-fee"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Modal className={classes.modal} open={openModal}>
        <Box className={classes.modalBox}>
          <Typography variant="h6">Deposits and Fees</Typography>
          <Typography className={classes.shadowedLabel}>
            Choose whether you want to charge clients a fee for canceling or rescheduling within 24 hours notice:
          </Typography>
          <FeeTypesForm
            data={{
              cancellationFee: userGroup?.cancellationFee || 0,
              depositFee: userGroup?.depositFee || 0,
            }}
            onChange={(data) => {
              setDepositFee(+(data?.depositFee || 0));
              setCancellationFee(+(data?.cancellationFee || 0));
            }}
          />

          <Box display="flex" justifyContent="flex-end">
            <Button title="Cancel" onClick={() => setOpenModal(false)} className={classes.cancelButton} />
            <Button
              title={isUpdating ? 'Saving...' : 'Save'}
              onClick={() => updateDepositsAndFees()}
              className={classes.submitButton}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DepositsAndFees;
