import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@material-ui/core';

interface DeliveryDisclaimerModalProps {
  open: boolean;
  onClose: () => void;
}

const DeliveryDisclaimerModal: React.FC<DeliveryDisclaimerModalProps> = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <DialogTitle>Delivery Policy</DialogTitle>
    <DialogContent>
      <Box p={2}>
        <Typography paragraph>All inventory orders are final once processed with the supplier.</Typography>

        <Typography paragraph>
          We do not accept refunds for pharmaceutical products. Please ensure that you are available to sign for your
          package on the scheduled delivery date.
        </Typography>

        <Typography paragraph>
          Please note that we have no control over the delivery service chosen by the supplier.
        </Typography>

        <Typography paragraph>
          FedEx will make up to three delivery attempts before returning the package to the sender, while UPS only
          guarantees one attempt.
        </Typography>

        <Typography paragraph>
          If a package is returned due to missed delivery attempts caused by customer error, we cannot guarantee a
          replacement order.
        </Typography>

        <Typography>
          Thank you,
          <br />
          Portrait
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="contained">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeliveryDisclaimerModal;
