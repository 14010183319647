import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import {
  PRACTITIONER_STANDING_ORDER,
  PRACTITIONER_STANDING_ORDERS,
  STANDING_ORDER_TEMPLATES,
} from '../../constants/reactQuery.keys';
import StandingOrders from '../../services/StandingOrders';
import compile from '../../utils/toastMessagesCompiler';

export function useStandingOrderTemplates() {
  const response = useQuery([STANDING_ORDER_TEMPLATES], () => StandingOrders.getStandingOrderTemplates());
  return {
    ...response,
    data: response.data,
  };
}

export function usePractitionerStandingOrders() {
  return useQuery([PRACTITIONER_STANDING_ORDERS], () => StandingOrders.getPractitionerStandingOrders(), {
    refetchOnWindowFocus: true,
    staleTime: 5000, // Only refetch if data is older than 5 seconds
  });
}

export function usePractitionerStandingOrder(id: number) {
  return useQuery([PRACTITIONER_STANDING_ORDER], () => StandingOrders.getPractitionerStandingOrder(id), {
    refetchOnWindowFocus: true,
  });
}

export const useUpdatePractitionerStandingOrders = (successCallback?: () => void): UseMutationResult =>
  useMutation((params: any) => StandingOrders.updatePractitionerStandingOrder(params), {
    onSuccess: (success: boolean) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }
      showSnackbar(
        compile('generic.success_message', { element: 'Standing order', action: 'updated' }),
        SNACKBAR_SUCCESS
      );
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });

export const useCreatePractitionerStandingOrder = (successCallback?: (data?: any) => void): UseMutationResult =>
  useMutation((params: any) => StandingOrders.createPractitionerStandingOrder(params), {
    onSuccess: ({ success, standingOrder }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }
      showSnackbar(
        compile('generic.success_message', { element: 'Standing order', action: 'created' }),
        SNACKBAR_SUCCESS
      );
      successCallback?.({ standingOrder });
    },
    onError: useReactQueryOnErrorHandler,
  });
