import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Accept, useDropzone } from 'react-dropzone';
import { ReactComponent as DocumentIcon } from 'src/assets/images/file_upload_icon.svg';
import { useStyles } from './DragAndDrop.styles';
import FilesList from './FilesList';

const PREFIX_MESSAGE = "We're sorry, but the file you're attempting to upload is not acceptable";

interface FileValidationRule {
  validate: (file: File) => boolean;
  getMessage: () => string;
}

function DragAndDropComponent({
  acceptedFileType,
  onFileChange,
  acceptMultiple = false,
  hideRemoveFile = false,
  hideDocumentIcon = false,
  height,
  error,
  helperText,
  disabled = false,
  sizeLimit,
}: {
  acceptedFileType: string | Accept;
  onFileChange: (files: File[]) => void;
  acceptMultiple?: boolean;
  hideRemoveFile?: boolean;
  hideDocumentIcon?: boolean;
  height?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  sizeLimit?: number;
}) {
  const [files, setFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState<string | null>(null);
  const classes = useStyles();

  const formatFileTypes = () =>
    (acceptedFileType as string)
      .split(',')
      .map((type) => (type.split('/')[1] || type.split('/')[0]).toUpperCase())
      .join(', ');

  const onRemoveDoc = (filesList: File[]) => {
    onFileChange([]);
    setFiles(filesList);
  };

  const fileTypeValidator = (fileTypes: string): FileValidationRule => ({
    validate: (file: File) =>
      fileTypes
        .split(',')
        .map((t) => t.trim())
        .includes(file.type),
    getMessage: () => `Please upload one of the following file types: ${formatFileTypes()}.`,
  });

  const fileSizeValidator = (fileSizeLimit: number): FileValidationRule => ({
    validate: (file: File) => file.size <= fileSizeLimit,
    getMessage: () => `File size must be less than ${fileSizeLimit / 1024 / 1024} MB.`,
  });

  const validators: FileValidationRule[] = [
    fileTypeValidator(acceptedFileType as string),
    ...(sizeLimit ? [fileSizeValidator(sizeLimit)] : []),
  ];

  const onDrop = useCallback(
    (droppedFiles) => {
      if (disabled) {
        return;
      }

      const errors: string[] = [];

      droppedFiles.forEach((file: File) => {
        validators.forEach((validator) => {
          if (!validator.validate(file)) {
            errors.push(validator.getMessage());
          }
        });
      });

      if (errors.length > 0) {
        setFileError(`${PREFIX_MESSAGE}: ${errors.join('\n')}`);
      } else {
        setFileError(null);
        onFileChange(droppedFiles);
        setFiles(droppedFiles);
      }
    },
    [acceptedFileType, onFileChange, disabled, sizeLimit]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    onDrop,
    accept: acceptedFileType ? (acceptedFileType as Accept) : undefined,
    multiple: acceptMultiple,
    disabled,
  });

  useEffect(() => {
    setFileError(error ? (helperText as string) : null);
  }, [error]);

  return (
    <Box className={classes.container}>
      <Box
        {...getRootProps()}
        style={{
          width: '100%',
          height: height || '100%',
          minHeight: height || '120px',
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          border: `dashed ${fileError || error ? '#f44336' : 'lightgrey'} 1px`,
          backgroundColor: 'transparent',
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <input {...getInputProps()} disabled={disabled} />

        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <Box
            style={{
              width: '100%',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            {!hideDocumentIcon && (
              <Box className={classes.documentIconContainer}>
                <DocumentIcon className={classes.documentIcon} />
              </Box>
            )}
            <div style={{ margin: 0, textAlign: 'center' }}>
              <p>
                <span style={{ textDecoration: 'underline', fontWeight: 'bolder' }}>Click to upload</span>
                {` ${acceptMultiple ? 'files' : 'single file'} or Drag and Drop`}
              </p>
              <p
                style={{
                  fontWeight: 100,
                  color: 'grey',
                  margin: 0,
                }}
              >
                File types: {formatFileTypes()}
              </p>
            </div>
          </Box>
        )}
        {isDragAccept && !acceptMultiple && files.length > 1 && <p>Can&apos;t upload multiple documents at once</p>}
        {fileError && <p style={{ color: 'red', textAlign: 'center' }}>{fileError}</p>}
        {isDragAccept && files.length === 1 && <p>File name: {files[0].name}</p>}
      </Box>
      {!hideRemoveFile && <FilesList files={files} onRemoveDocument={onRemoveDoc} />}
    </Box>
  );
}

export default DragAndDropComponent;
