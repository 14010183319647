import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useStyles } from './OtherTenders.styles';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { TenderSection } from './TenderSection';
import {
  ALLE_WITH_CHERRY_TENDER,
  ASPIRE_TENDER,
  BRILLIANT_TENDER,
  CARE_CREDIT_TENDER,
  PATIENTFI_TENDER,
  TENDER_TYPES,
  XPERIENCE_TENDER,
} from '../../../constants/checkout.constants';
import { ITender, TenderType } from '../../../interfaces/ITransaction.interfaces';
import { addCheckoutTender, TenderParams } from '../../../hooks/serviceVisit/useTenders';
import { ReferralCredits } from './ReferralCredits';
import { IPatientBasicInfo } from '../../../types/Patient';
import { IReferralCredits } from '../../../types/ReferralCredits';
// import AspireSection from './Aspire/AspireSection';
// import IServices from '../../../interfaces/IServices';
// import { ILineItem } from '../../../interfaces/IServiceVisits';
import { AspireTenderLogo } from '../../Points/AspireTenderLogo';

const OtherTenders = ({
  serviceVisitId,
  tenders,
  patient,
  disableLoyaltyPrograms,
  referralCredits,
  appliedReferralCredits,
  isLoadingAppliedReferred,
  refetchAppliedReferralCredits,
  remainingToPay,
  setOtherTenderTotal,
  resetTenders,
  setResetTenders,
  showReferralSection,
  isPractitioner,
}: {
  serviceVisitId: number;
  tenders: ITender[];
  patient: IPatientBasicInfo;
  disableLoyaltyPrograms: boolean;
  referralCredits: IReferralCredits[];
  appliedReferralCredits: IReferralCredits[];
  isLoadingAppliedReferred: boolean;
  refetchAppliedReferralCredits: () => void;
  remainingToPay: number;
  setOtherTenderTotal: (total: number) => void;
  resetTenders: boolean;
  setResetTenders: (v: boolean) => void;
  showReferralSection?: boolean;
  isPractitioner: boolean;
}) => {
  const classes = useStyles();
  const [aspireVoucher, setAspireVoucher] = useState<string>('');
  const [aspireValue, setAspireValue] = useState<string>('');
  const [brilliantVoucher, setBrilliantVoucher] = useState<string>('');
  const [brilliantValue, setBrilliantValue] = useState<string>('');
  const [alleCherryValue, setAlleCherryValue] = useState<string>('');
  const [alleCherryVoucher, setAlleCherryVoucher] = useState<string>('');
  const [xperienceVoucher, setXperienceVoucher] = useState<string>('');
  const [xperienceValue, setXperienceValue] = useState<string>('');
  const [careCreditVoucher, setCareCreditVoucher] = useState<string>('');
  const [careCreditValue, setCareCreditValue] = useState<string>('');
  const [patientfiValue, setPatientfiValue] = useState<string>('');
  const [patientFiVoucher, setPatientFiVoucher] = useState<string>('');

  const { mutateAsync: applyTender } = addCheckoutTender(+serviceVisitId);

  const totalTenderValue = useMemo(
    () => +brilliantValue + +careCreditValue + +xperienceValue + +aspireValue + +patientfiValue + +alleCherryValue,
    [brilliantValue, alleCherryValue, careCreditValue, xperienceValue, aspireValue, patientfiValue]
  );

  useEffect(() => {
    setOtherTenderTotal(totalTenderValue);
  }, [totalTenderValue]);

  useEffect(() => {
    if (resetTenders) {
      setAspireVoucher('');
      setAspireValue('');
      setBrilliantVoucher('');
      setBrilliantValue('');
      setAlleCherryVoucher('');
      setAlleCherryValue('');
      setXperienceVoucher('');
      setXperienceValue('');
      setCareCreditVoucher('');
      setCareCreditValue('');
      setPatientFiVoucher('');
      setPatientfiValue('');
      setResetTenders(false);
    }
  }, [resetTenders]);

  useEffect(() => {
    TENDER_TYPES.forEach((tenderType) => {
      const matchingTender = tenders?.find((tender) => tender.tenderType === tenderType);
      if (matchingTender) {
        switch (tenderType) {
          case BRILLIANT_TENDER:
            setBrilliantValue(matchingTender.value.toString());
            setBrilliantVoucher(matchingTender.voucher);
            break;
          case ALLE_WITH_CHERRY_TENDER:
            setAlleCherryValue(matchingTender.value.toString());
            setAlleCherryVoucher(matchingTender.voucher);
            break;
          case CARE_CREDIT_TENDER:
            setCareCreditValue(matchingTender.value.toString());
            setCareCreditVoucher(matchingTender.voucher);
            break;
          case XPERIENCE_TENDER:
            setXperienceValue(matchingTender.value.toString());
            setXperienceVoucher(matchingTender.voucher);
            break;
          case ASPIRE_TENDER:
            setAspireValue(matchingTender.value.toString());
            setAspireVoucher(matchingTender.voucher);
            break;
          case PATIENTFI_TENDER:
            setPatientfiValue(matchingTender.value.toString());
            setPatientFiVoucher(matchingTender.voucher);
            break;
          default:
            break;
        }
      }
    });
  }, [tenders]);

  useEffect(() => {
    if (disableLoyaltyPrograms) {
      const tenderTypes = [CARE_CREDIT_TENDER, ALLE_WITH_CHERRY_TENDER, PATIENTFI_TENDER];

      setCareCreditVoucher('');
      setCareCreditValue('');
      setAlleCherryValue('');
      setAlleCherryVoucher('');
      setPatientfiValue('');
      setPatientFiVoucher('');

      tenderTypes.forEach((tenderType) => {
        saveTender({
          value: 0,
          voucher: '',
          tenderType: tenderType as TenderType,
        });
      });
    }
  }, [disableLoyaltyPrograms]);

  const toFloatNumber = (value: number): number => Number(value.toFixed(2));

  const isTotalTenderValueValid = (inputObj: any, tenderValue: string): boolean => {
    const preTotal = totalTenderValue - (parseFloat(tenderValue) || 0);
    const totalTender = (inputObj.floatValue || 0) + preTotal;
    const isValid = toFloatNumber(remainingToPay) + toFloatNumber(totalTenderValue) - toFloatNumber(totalTender) >= 0;

    if (!isValid) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.limit_exceeded'),
          type: 'info',
        },
      });
    }
    return isValid;
  };

  const tenderMap: Record<string, Record<string, string>> = {
    brilliant: {
      voucher: brilliantVoucher,
      value: brilliantValue,
    },
    alleCherry: {
      voucher: alleCherryVoucher,
      value: alleCherryValue,
    },
    careCredit: {
      voucher: careCreditVoucher,
      value: careCreditValue,
    },
    xperience: {
      voucher: xperienceVoucher,
      value: xperienceValue,
    },
    aspire: {
      voucher: aspireVoucher,
      value: aspireValue,
    },
    patientfi: {
      voucher: patientFiVoucher,
      value: patientfiValue,
    },
  };

  const saveTender = async ({ value, voucher, tenderType }: TenderParams) => {
    if (isPractitioner) {
      await applyTender({
        tenderType,
        value: value || 0,
        voucher,
      });
    }
  };

  const updateTender = useCallback(debounce(saveTender, 500), []);

  const handleVoucherSave = (voucher: string, tenderType: TenderType) => {
    switch (tenderType) {
      case TenderType.Brilliant:
        setBrilliantVoucher(voucher);
        break;
      case TenderType.AlleCherry:
        setAlleCherryVoucher(voucher);
        break;
      case TenderType.CareCredit:
        setCareCreditVoucher(voucher);
        break;
      case TenderType.Xperience:
        setXperienceVoucher(voucher);
        break;
      case TenderType.Aspire:
        setAspireVoucher(voucher);
        break;
      case TenderType.Patientfi:
        setPatientFiVoucher(voucher);
        break;
      default:
        break;
    }

    const value = tenderMap[tenderType].value as string;
    updateTender({
      value: +value,
      voucher,
      tenderType,
    });
  };

  const handleValueSave = (value: string, tenderType: TenderType) => {
    switch (tenderType) {
      case TenderType.Brilliant:
        setBrilliantValue(value);
        break;
      case TenderType.AlleCherry:
        setAlleCherryValue(value);
        break;
      case TenderType.CareCredit:
        setCareCreditValue(value);
        break;
      case TenderType.Xperience:
        setXperienceValue(value);
        break;
      case TenderType.Aspire:
        setAspireValue(value);
        break;
      case TenderType.Patientfi:
        setPatientfiValue(value);
        break;
      default:
        break;
    }

    const voucher = tenderMap[tenderType].voucher as string;
    updateTender({
      value: +value,
      voucher,
      tenderType,
    });
  };

  return (
    <div className={classes.mainSection}>
      <div className={classes.otherTenderTitle}>Other Tenders</div>
      <div className={classes.tendersSection}>
        <TenderSection
          tenderName="All&#x0113;"
          tenderVoucher={brilliantVoucher}
          tenderValue={brilliantValue}
          tenderVoucherOnSave={(e) => handleVoucherSave(e.target.value, BRILLIANT_TENDER)}
          tenderValueOnSave={(e) => handleValueSave(e.target.value, BRILLIANT_TENDER)}
          isTotalTenderValueValid={isTotalTenderValueValid}
        />
        <TenderSection
          tenderName="All&#x0113; Payment with Cherry"
          tenderVoucher={alleCherryVoucher}
          tenderValue={alleCherryValue}
          tenderVoucherOnSave={(e) => handleVoucherSave(e.target.value, ALLE_WITH_CHERRY_TENDER)}
          tenderValueOnSave={(e) => handleValueSave(e.target.value, ALLE_WITH_CHERRY_TENDER)}
          isTotalTenderValueValid={isTotalTenderValueValid}
          disabled={disableLoyaltyPrograms}
        />
        <TenderSection
          tenderName={null}
          tenderIcon={<AspireTenderLogo onSwap={() => {}} />}
          tenderVoucher={aspireVoucher}
          tenderValue={aspireValue}
          tenderVoucherOnSave={(e) => handleVoucherSave(e.target.value, ASPIRE_TENDER)}
          tenderValueOnSave={(e) => handleValueSave(e.target.value, ASPIRE_TENDER)}
          isTotalTenderValueValid={isTotalTenderValueValid}
        />
        <TenderSection
          tenderName="CareCredit"
          tenderVoucher={careCreditVoucher}
          tenderValue={careCreditValue}
          tenderVoucherOnSave={(e) => handleVoucherSave(e.target.value, CARE_CREDIT_TENDER)}
          tenderValueOnSave={(e) => handleValueSave(e.target.value, CARE_CREDIT_TENDER)}
          isTotalTenderValueValid={isTotalTenderValueValid}
          disabled={disableLoyaltyPrograms}
        />
        <TenderSection
          tenderName="Xperience"
          tenderVoucher={xperienceVoucher}
          tenderValue={xperienceValue}
          tenderVoucherOnSave={(e) => handleVoucherSave(e.target.value, XPERIENCE_TENDER)}
          tenderValueOnSave={(e) => handleValueSave(e.target.value, XPERIENCE_TENDER)}
          isTotalTenderValueValid={isTotalTenderValueValid}
        />
        <TenderSection
          tenderName="PatientFi"
          tenderVoucher={patientFiVoucher}
          tenderValue={patientfiValue}
          tenderVoucherOnSave={(e) => handleVoucherSave(e.target.value, PATIENTFI_TENDER)}
          tenderValueOnSave={(e) => handleValueSave(e.target.value, PATIENTFI_TENDER)}
          isTotalTenderValueValid={isTotalTenderValueValid}
          disabled={disableLoyaltyPrograms}
        />
        {(patient.isAdvocate || true) && showReferralSection && (
          <ReferralCredits
            referralCredits={referralCredits}
            patient={patient}
            transactionStatus="UNPAID"
            appliedReferralCredits={appliedReferralCredits}
            isLoadingAppliedReferred={isLoadingAppliedReferred}
            serviceVisitId={serviceVisitId}
            refetchAppliedReferralCredits={refetchAppliedReferralCredits}
          />
        )}
      </div>
    </div>
  );
};

export default OtherTenders;
