import React, { useState, useEffect } from 'react';

import { Modal, CardContent, TextField, Radio, FormControlLabel, makeStyles } from '@material-ui/core';

import { Button } from '../../common/Button';
import { Card, Title } from '../../common/card';
import { FREQUENCIES_VALUES } from '../../../constants/medicalProfile.constants';
import { MEDICATION_MESSAGES } from '../../../constants/patient.constants';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface Props {
  isOpen: boolean;
  closeModal: any;
  medicationInfo: any[];
  dispatchSave: any;
  // eslint-disable-next-line react/require-default-props
  previousValues?: any[];
}
const OTHER_FREQUENCY = 'Other';

export const MedicationsModal = ({ isOpen, closeModal, dispatchSave, medicationInfo, previousValues }: Props) => {
  const classes = useStyles();
  const [medication, setMedication] = useState<any | null>(null);
  const [medicationIndex, setMedicationIndex] = useState(0);
  const [using, setUsing] = useState<boolean | null>(null);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [selectedPotency, setSelectedPotency] = useState('');
  const [values, setValues] = useState<any>([]);
  const [selectedVariation, setSelectedVariation] = useState<any>({});
  const [other, setOther] = useState('');

  useEffect(() => {
    setMedication(medicationInfo[0]);
    if (medicationInfo[0]) {
      setPreviousValues(medicationInfo[0]);
    }
  }, [medicationInfo]);

  const setPreviousValues = (ObjValues: any) => {
    const previouslySelectedValues = previousValues?.find(({ name }: any) => name === ObjValues.name);
    if (previouslySelectedValues) {
      const { frequency, potency, using: isUsing, vehicle } = previouslySelectedValues;
      setSelectedPotency(potency || '');
      setUsing(isUsing ?? null);
      setSelectedVehicle(vehicle || '');

      if (vehicle) {
        const variation = ObjValues.variations?.find(
          (value: any) => value.vehicle === previouslySelectedValues.vehicle
        );
        setSelectedVariation(variation);
      }

      if (!FREQUENCIES_VALUES.includes(frequency)) {
        setSelectedFrequency('Other');
        setOther(frequency);
      } else {
        setSelectedFrequency(frequency);
      }
    } else {
      resetState();
    }
  };

  const onSelectVehicle = (vehicleSelected: string, variations: any[]) => {
    setSelectedPotency('');
    setSelectedFrequency('');
    const variation = variations.find(({ vehicle }: any) => vehicle === vehicleSelected);
    setSelectedVariation(variation);
    setSelectedVehicle(vehicleSelected);
  };

  const resetState = () => {
    setSelectedPotency('');
    setSelectedFrequency('');
    setUsing(null);
    setSelectedVehicle('');
    setSelectedVariation(null);
    setOther('');
  };

  const onCloseModal = () => {
    closeModal();
    resetState();
    setMedicationIndex(0);
    setMedication([]);
    setValues([]);
  };

  const action = () => {
    const valuesAux = [...values];
    valuesAux.push({
      algoliaId: medication.objectID,
      comments: null,
      frequency: selectedFrequency === 'Other' ? other : selectedFrequency || null,
      name: medication.name,
      potency: selectedPotency || null,
      using,
      vehicle: selectedVehicle || null,
    });
    setValues(valuesAux);

    if (medicationIndex !== medicationInfo.length - 1) {
      setPreviousValues(medicationInfo[medicationIndex + 1]);
      setMedicationIndex(medicationIndex + 1);
      setMedication(medicationInfo[medicationIndex + 1]);
    } else {
      dispatchSave(valuesAux);
      onCloseModal();
    }
  };

  if (!medication) {
    return null;
  }

  const { name, variations } = medication;
  const medicationAreYouUsingMsg = MEDICATION_MESSAGES.areYouUsing.replace('$NAME', name);
  const medicationWhatTypeMsg = MEDICATION_MESSAGES.whatType.replace('$NAME', name);
  const medicationPotencyMsg = MEDICATION_MESSAGES.potency.replace('$NAME', name);
  const medicationFrequencyMsg = MEDICATION_MESSAGES.frequency.replace('$NAME', name);
  return (
    <Modal open={isOpen} onClose={onCloseModal} className={classes.modal}>
      <Card>
        <CardContent>
          <Title title="Additional Details" />
          <div>
            <div>{medicationAreYouUsingMsg}</div>
            <FormControlLabel
              value="end"
              control={
                <Radio
                  color="primary"
                  checked={using === true}
                  onClick={() => {
                    setUsing(true);
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value="end"
              control={
                <Radio
                  color="primary"
                  checked={using === false}
                  onClick={() => {
                    setUsing(false);
                  }}
                />
              }
              label="No"
            />
            {variations && variations[0].vehicle && using && <div>{medicationWhatTypeMsg}</div>}
            {variations &&
              variations[0].vehicle &&
              using &&
              variations.map(({ vehicle }: any) => (
                <FormControlLabel
                  key={vehicle}
                  label={vehicle}
                  value="end"
                  control={
                    <Radio
                      color="primary"
                      onClick={() => {
                        onSelectVehicle(vehicle, variations);
                      }}
                      checked={selectedVehicle === vehicle}
                    />
                  }
                />
              ))}
            {!!selectedVehicle && using && (
              <>
                {selectedVariation?.potency && <div>{medicationPotencyMsg}</div>}
                {selectedVariation?.potency &&
                  selectedVariation.potency.map((potencyName: string) => (
                    <FormControlLabel
                      key={potencyName}
                      label={potencyName}
                      value="end"
                      control={
                        <Radio
                          color="primary"
                          checked={potencyName === selectedPotency}
                          onClick={() => {
                            setSelectedPotency(potencyName);
                          }}
                        />
                      }
                    />
                  ))}
                <div>{medicationFrequencyMsg}</div>
                {selectedVariation?.frequency.map((freqName: string) => (
                  <FormControlLabel
                    key={freqName}
                    label={freqName}
                    value="end"
                    control={
                      <Radio
                        color="primary"
                        checked={freqName === selectedFrequency}
                        onClick={() => {
                          setSelectedFrequency(freqName);
                        }}
                      />
                    }
                  />
                ))}
                {selectedFrequency === OTHER_FREQUENCY && (
                  <TextField
                    value={other}
                    style={{ width: '100%' }}
                    label={medicationFrequencyMsg}
                    variant="outlined"
                    onChange={(e) => {
                      setOther(e.currentTarget.value);
                    }}
                  />
                )}
              </>
            )}
            {variations && !variations[0].vehicle && using && (
              <>
                <div>{medicationFrequencyMsg}</div>
                {variations[0]?.frequency?.map((freqName: string) => (
                  <FormControlLabel
                    key={freqName}
                    label={freqName}
                    value="end"
                    control={
                      <Radio
                        checked={freqName === selectedFrequency}
                        onClick={() => {
                          setSelectedFrequency(freqName);
                        }}
                        color="primary"
                      />
                    }
                  />
                ))}
                {selectedFrequency === OTHER_FREQUENCY && (
                  <TextField
                    value={other}
                    style={{ width: '100%' }}
                    label={medicationFrequencyMsg}
                    variant="outlined"
                    onChange={(e) => {
                      setOther(e.currentTarget.value);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </CardContent>
        <CardContent>
          <Button
            title="Cancel"
            style={{ color: '#12574d', backgroundColor: 'none', border: 'none' }}
            onClick={onCloseModal}
          />
          <Button
            title={medicationIndex === medicationInfo.length - 1 ? 'Save' : 'Continue'}
            style={{ color: '#000000', backgroundColor: '#e7eeed', marginRight: '0' }}
            onClick={action}
          />
        </CardContent>
      </Card>
    </Modal>
  );
};
