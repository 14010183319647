import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { CheckCircleOutline, Schedule, HighlightOff, Email, Send, CancelScheduleSend } from '@material-ui/icons';
import {
  PENDING_TAG_BACKGROUND_COLOR,
  PENDING_TAG_FONT_COLOR,
} from 'src/pages/Onboarding/AccountSettings/AccountSettings.styles';
import { AppointmentStatusEnum } from './MedspaCalendar/AppointmentStatusEnum';

const useStyle = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    height: '1.8rem',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.25rem',
    borderRadius: '0.25rem',
    padding: '0.25rem 0.5rem',
    backgroundColor: '#D3D3D3',
    '&.small': {
      height: '1.2rem',
      fontSize: '0.75rem',
    },
    '&.created': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.completed': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.confirmed': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.rescheduled': {
      backgroundColor: PENDING_TAG_BACKGROUND_COLOR,
      color: PENDING_TAG_FONT_COLOR,
    },
    '&.cancelled, &.canceled': {
      backgroundColor: '#FFE1E1',
      color: '#B83F3F',
    },
    '&.pending_confirmation': {
      maxWidth: '10.5rem',
    },
    '&.email_sent': {
      backgroundColor: '#6b8bc3',
      color: 'white',
    },
    '&.email_not_sent': {
      backgroundColor: '#9fb2d8',
      color: 'white',
    },
    '&.sms_sent': {
      backgroundColor: '#485d82',
      color: 'white',
    },
    '&.sms_not_sent': {
      backgroundColor: '#9fb2d8',
      color: 'white',
    },
    '&.charged': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
  },
  completed: {
    color: '#2C6109',
    backgroundColor: '#DFF8CD',
  },
  historyStatusIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

const ACTION_ICONS: { [key in AppointmentStatusEnum]: any } = {
  [AppointmentStatusEnum.RESERVED]: Schedule,
  [AppointmentStatusEnum.PENDING_CONFIRMATION]: CheckCircleOutline,
  [AppointmentStatusEnum.COMPLETED]: CheckCircleOutline,
  [AppointmentStatusEnum.CONFIRMED]: CheckCircleOutline,
  [AppointmentStatusEnum.CREATED]: CheckCircleOutline,
  [AppointmentStatusEnum.CREATED_FROM_ACUITY]: CheckCircleOutline,
  [AppointmentStatusEnum.RESCHEDULED]: Schedule,
  [AppointmentStatusEnum.CANCELLED]: HighlightOff,
  [AppointmentStatusEnum.EMAIL_SENT]: Email,
  [AppointmentStatusEnum.EMAIL_NOT_SENT]: Email,
  [AppointmentStatusEnum.SMS_SENT]: Send,
  [AppointmentStatusEnum.SMS_NOT_SENT]: CancelScheduleSend,
  [AppointmentStatusEnum.CHARGED]: CheckCircleOutline,
};
const ACTION_TEXT: { [key in AppointmentStatusEnum]: any } = {
  [AppointmentStatusEnum.RESERVED]: 'Reserved',
  [AppointmentStatusEnum.PENDING_CONFIRMATION]: 'Pending Confirmation',
  [AppointmentStatusEnum.COMPLETED]: 'Completed',
  [AppointmentStatusEnum.CONFIRMED]: 'Confirmed',
  [AppointmentStatusEnum.CREATED]: 'Created',
  [AppointmentStatusEnum.CREATED_FROM_ACUITY]: 'Created',
  [AppointmentStatusEnum.RESCHEDULED]: 'Rescheduled',
  [AppointmentStatusEnum.CANCELLED]: 'Cancelled',
  [AppointmentStatusEnum.EMAIL_SENT]: 'Email Sent',
  [AppointmentStatusEnum.EMAIL_NOT_SENT]: 'Email Not Sent',
  [AppointmentStatusEnum.SMS_SENT]: 'SMS Sent',
  [AppointmentStatusEnum.SMS_NOT_SENT]: 'SMS Not Sent',
  [AppointmentStatusEnum.CHARGED]: 'Charged',
};

export const AppointmentStatusTag: React.FC<{ action: AppointmentStatusEnum; description?: string; size?: string }> = ({
  action = AppointmentStatusEnum.CANCELLED,
  description,
  size,
}) => {
  const classes = useStyle();
  const AppointmentIconComponent = action in ACTION_ICONS ? ACTION_ICONS[action] : Box;
  return (
    <Tooltip title={description || ''}>
      <Box className={classnames(classes.root, action, size)}>
        <AppointmentIconComponent className={classes.historyStatusIcon} />
        {/* action with first word capitalized: */}
        {action in ACTION_TEXT ? ACTION_TEXT[action] : action}
      </Box>
    </Tooltip>
  );
};
