import { Box, CircularProgress, InputAdornment, withStyles } from '@material-ui/core';
import React, { useEffect, useContext, useMemo, useRef, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { INPUT_DATE_FORMAT } from 'src/constants/inventory.constants';
import moment from 'moment';
import { DeliveryDateParams, PickersDate } from 'src/interfaces/IInventoryOrder';
import { useBlackoutDates, useUpdateDeliveryDate } from 'src/hooks/queries/useInventoryOrders';
import { Today as TodayIcon } from '@material-ui/icons';
import { invalidDeliveryDate } from 'src/utils/inventoryOrdering';
import { formatBlackoutDates } from 'src/utils/inventoryOrdering/deliveryDate';
import { OrderContext } from './index';

const DeliveryDate: React.FC<DeliveryDateParams> = ({
  helperText,
  disableUnderline,
  disableIcon,
  className,
  classes,
  ProgressParams,
  onDateSelect,
}) => {
  const [hasResetDate, setHasResetDate] = useState(false);
  const { orderData, blockProgress, isLegacy } = useContext(OrderContext) || {};
  const { mutateAsync: updateDeliveryDate } = useUpdateDeliveryDate(orderData?.id ?? 0);
  const { data: blackoutDates, isLoading: isLoadingBlackoutDates } = useBlackoutDates();

  const blackoutDateStrings = useMemo(() => formatBlackoutDates(blackoutDates ?? []), [blackoutDates]);
  const desiredDeliveryDate = orderData?.desiredDeliveryDate ? moment.utc(orderData.desiredDeliveryDate) : null;

  const deliveryDateRef = useRef<string | null>(desiredDeliveryDate?.format(INPUT_DATE_FORMAT) ?? null);
  const isLoading =
    !!deliveryDateRef.current && deliveryDateRef.current !== desiredDeliveryDate?.format(INPUT_DATE_FORMAT);

  const handleChangeDeliveryDate = async (date: PickersDate): Promise<void> => {
    const newDate = date?.format(INPUT_DATE_FORMAT) ?? null;
    blockProgress?.(true);
    deliveryDateRef.current = newDate;
    await updateDeliveryDate(newDate);
    !!newDate && setHasResetDate(false);
    blockProgress?.(false);
    onDateSelect?.();
  };

  const shouldDisableDate = (date: PickersDate): boolean =>
    !date ||
    invalidDeliveryDate({
      date,
      blackoutDates: blackoutDateStrings,
      isLegacy,
    });

  useEffect(() => {
    if (!hasResetDate && !!desiredDeliveryDate && shouldDisableDate(desiredDeliveryDate)) {
      try {
        handleChangeDeliveryDate(null);
      } finally {
        setHasResetDate(true);
      }
    }
  }, [desiredDeliveryDate, blackoutDateStrings]);

  return isLoadingBlackoutDates || isLoading ? (
    <Box
      display="flex"
      width="100%"
      height={ProgressParams?.height}
      margin={ProgressParams?.margin}
      className={ProgressParams?.className}
    >
      <CircularProgress size={ProgressParams?.size ?? 25} />
    </Box>
  ) : (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        placeholder="Select a date"
        value={desiredDeliveryDate}
        format="MMM DD, YYYY"
        autoOk
        disablePast
        disableToolbar
        shouldDisableDate={shouldDisableDate}
        onChange={handleChangeDeliveryDate}
        data-confirmed={!!desiredDeliveryDate}
        data-testid="delivery date"
        className={className ?? classes?.deliveryDateInput}
        helperText={helperText}
        InputProps={{
          endAdornment: disableIcon ? null : (
            <InputAdornment position="end">
              <TodayIcon />
            </InputAdornment>
          ),
          disableUnderline: !!disableUnderline,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles({
  deliveryDateInput: {
    margin: 'auto 25px auto 0',
    width: '180px',
    '& *': { fontSize: '0rem' },
    '&[data-confirmed="true"] *': { fontSize: '1rem' },
  },
})(DeliveryDate);
